.svg-map {
	width: 100%;
	height: auto;
	stroke: #666;
	stroke-width: 1;
	stroke-linecap: round;
	stroke-linejoin: round;
	transition: all .2s;
	max-width: 1500px;
	padding: 20px 10px 20px 0;
}
.svg-map__location {
	fill: #f1f1f1;
	cursor: pointer;
}
.svg-map__location:focus, .svg-map__location:hover {
	fill: #d1d1d1;
	outline: 0;
	transition: all .2s;
}
.svg-map__location[aria-checked=true] {
	fill: #f4bc44;
}

