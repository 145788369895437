.breadcrumb {
	margin: 0;
	font-weight: $breadcrumb-font-weight;

	& .breadcrumb-item {
		font-size: 15px;
		line-height: $page-header-line-height * $page-header-font-size;

		&.active {
			color: $green2;
			font-weight: 600;
		}

		& + .breadcrumb-item {
			@if $enable-rtl {
				padding-left: 0;
				padding-right: .5rem;
			}
			&:before {
				@if $enable-rtl {
					padding-right: 0;
					padding-left: .5rem;
					float: right;
				}
			}
		}
		& a {
			font-size: 15px;
			color: $blue;
			text-decoration: none;

			&:hover {
				//text-decoration: underline;
			}
		}
	}
}
